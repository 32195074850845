import React from 'react';
import kkdgLogo from '../assets/images/kkdg-v1.png';
//import kkdgDogLeftV1 from '../assets/images/kkdg-dog-v6.png';
//import kkdgDogRightV1 from '../assets/images/kkdg-dog-v5.png'; // Import new image
import './Header.css';

const Header = () => {
  return (
    <header className="header">
      <div className="header-top">
        <img 
          src={kkdgLogo} 
          alt="KK Dog Grooming Logo" 
          className="logo" 
        />
        <nav className="nav" aria-label="Main navigation">
          <a href="/">About us</a>
          <a href="/services">Services</a>
          <a href="/articles">Articles</a>
          <a href="/contact">Contact us</a>
        </nav>
        <button className="get-started-btn">
          <a href="tel:+447415505151" id="telephone" aria-label="Call us to get started">
            Call 07415505151 <br />To Get Started
          </a>
        </button>
      </div>
      <div className="header-content">
        <div className="text-content"></div>
        
      </div>
    </header>
  );
};

export default Header;
