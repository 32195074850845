// src/views/homepage.js
import React from 'react';
import { Helmet } from 'react-helmet';
import './Homepage.css';
import TopFold from './TopFold.js';
import Content from './contents/HomeContent.js';
import Footer from './Footer.js'

const HomePage = () => {

    return (
        <div className="kkdg-homepage">
            <Helmet>
                <title>Dog Grooming Service Milton Keynes | KK Dog Groomer</title>
                <meta name="description" content="KK Dog Groomer - Professional and friendly Dog Grooming Service in Milton Keynes" />
                <script src="https://kit.fontawesome.com/7ff6e2be82.js" crossorigin="anonymous"></script>
            </Helmet>
            <TopFold />
            <Content />
            <div data-bid="143547" data-url="https://app.revu.cloud"  data-aid="18812"><script src="https://widget.reviewability.com/js/widgetAdv.min.js" async></script></div><script class="json-ld-content" type="application/ld+json"></script>
            <Footer />
        </div>
    );
};

export default HomePage;
