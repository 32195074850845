import React from 'react';
import kkdgLogo from '../assets/images/kkdg-v1.png';
import kkdgDogLeftV1 from '../assets/images/kkdg-dog-v6.png';
import kkdgDogRightV1 from '../assets/images/kkdg-dog-v5.png'; // Import new image
//import kkdgTopFoldBgImg from '../assets/images/kkdg-tfbg-img.png'; //Used via css
import './TopFold.css';

const TopFold = () => {
  return (
    <topfold className="topfold">
      <div className="topfold-top">
        <img 
          src={kkdgLogo} 
          alt="KK Dog Grooming Logo" 
          className="logo" 
        />
        <nav className="nav" aria-label="Main navigation">
          <a href="/">About us</a>
          <a href="/services">Services</a>
          <a href="/articles">Articles</a>
          <a href="/contact">Contact us</a>
        </nav>
        <button className="get-started-btn">
          <a href="tel:+447415505151" id="telephone" aria-label="Call us to get started">
            Call 07415505151 <br />To Get Started
          </a>
        </button>
      </div>
      <div className="topfold-content top-fold-background-image" style={{ position: 'relative' }}>
        <div className="text-content">
          <h1 className="topfold-title">
            More than Dog Grooming Salon, it’s your dog’s happy place.
          </h1>
          <p className="topfold-subtitle">
            <a 
              href="https://maps.app.goo.gl/1T5QKxEgdZLaxkek9" 
              target="_blank" 
              rel="noopener noreferrer" 
              style={{ color: 'inherit', textDecoration: 'none' }}
              aria-label="View location on Google Maps"
            >
              30 Wharfside, Fenny Stratford, Bletchley, Milton Keynes MK2 2AZ
            </a>
          </p>
        </div>
        <div className="topfold-image-content-left">
          <img src={kkdgDogLeftV1} alt="Happy dog Left" className="dog-image-left" />
        </div>
        <div className="topfold-image-content-right">
          <img src={kkdgDogRightV1} alt="Happy dog Right" className="dog-image-right" />
        </div>
      </div>
    </topfold>
  );
};

export default TopFold;
