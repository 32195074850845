import React, { Suspense, lazy } from 'react';
import { Routes, Route, useNavigate } from 'react-router-dom';
import { Helmet } from 'react-helmet';
import Header from '../../Header.js';
import Footer from '../../Footer.js';
import './articles.css';

const Articles = () => {
  const navigate = useNavigate();

  // Dynamically import all articles from the folder
  const context = require.context('./', false, /\.js$/);
  const articles = context.keys()
    .filter(
      (filePath) => 
        !['./articleTemplate.js', './articles.js'].includes(filePath) // Exclude specific files
    )
    .reduce((acc, filePath) => {
      const fileName = filePath.replace('./', '').replace('.js', '');
      const Component = lazy(() => import(`./${fileName}`));
      acc[fileName] = { component: Component, title: fileName.replace(/_/g, ' ') }; // Format the title
      return acc;
    }, {});

  return (
    <div className="articlePage">
      <Helmet>
        <title>Articles | KK Dog Groomer</title>
        <meta name="description" content="Explore a variety of articles about dog grooming, care, and health." />
      </Helmet>
      <Header />
      <div className="article-container">
        <Routes>
          {/* Dynamically create routes for each article */}
          {Object.entries(articles).map(([path, { component: Component, title }]) => (
            <Route
              key={path}
              path={path}
              element={
                <Suspense fallback={<div>Loading...</div>}>
                  <Component />
                </Suspense>
              }
            />
          ))}
          {/* Fallback to Articles List */}
          <Route
            path="*"
            element={
              <div className="content-txt">
                <h1>Articles</h1>
                <ul>
                  {Object.entries(articles).map(([path, { title }]) => (
                    <li key={path}>
                      <a
                          href={`/articles/${path}`} // Add the `href` attribute
                          onClick={(e) => {
                            e.preventDefault(); // Prevent default navigation
                            navigate(`/articles/${path}`); // Use `navigate` for routing
                          }}
                          aria-label={`Read article titled ${title}`} // Describe the link for screen readers
                          tabIndex="0" // Ensure the link is keyboard accessible
                      >
                        {title}
                      </a>
                    </li>
                  ))}
                </ul>
              </div>
            }
          />
        </Routes>
      </div>
      <Footer />
    </div>
  );
};

export default Articles;