import React from 'react';
import './ContactContent.css';

const OpeningHours = () => {
    return (
        <div className="kkdg-opening-hours">
            <h2>Opening Hours</h2>
            <ul>
                <li>   Monday: <span>10:00 - 18:00</span></li>
                <li>  Tuesday: <span>10:00 - 18:00</span></li>
                <li>Wednesday: <span>10:00 - 18:00</span></li>
                <li> Thursday: <span>10:00 - 18:00</span></li>
                <li>   Friday: <span>10:00 - 18:00</span></li>
                <li> Saturday: <span>Closed</span></li>
                <li>   Sunday: <span>Closed</span></li>
            </ul>
        </div>
    );
};

const ContactContent = () => {
  return (
    <div className="contact-content">
        <h1>To Book a Grooming Appointment</h1>
        <p>Or have your questions or concerns answered call <button className='telephone'><a href="tel:+447415505151" id="telephone"><span>07415 505151</span></a></button></p>
        
        <div>
            <p>We are located at: <span className='address'>30 Wharfside, Fenny Stratford, Bletchley, Milton Keynes MK2 2AZ</span></p>
            <div className='g-map'>
                <iframe 
                    src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d2456.537623781329!2d-0.7179803875999998!3d51.99708067469587!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x487654e0b4e9e709%3A0x3f1941011640d857!2s30%20Wharfside%2C%20Fenny%20Stratford%2C%20Bletchley%2C%20Milton%20Keynes%20MK2%202AZ%2C%20UK!5e0!3m2!1sen!2sus!4v1733103094421!5m2!1sen!2sus" 
                    title='KK Dog Grooming Salon'
                    width="600" 
                    height="450" 
                    style={{ border:0 }}
                    allowfullscreen="" 
                    loading="lazy" 
                    referrerpolicy="no-referrer-when-downgrade">
                </iframe>
            </div>
        </div>

        <OpeningHours />

        {/*
        <div>
          <div id="popup-rating-widget"><script id="popup-rating-widget-script" src="https://widget.reviewability.com/js/popupWidget.min.js" data-gfspw="https://app.revu.cloud/popup-pixel/get/38ff66d16f3450eef8246494b244bb66b1dd1149" async></script></div>
          <div data-bid="143547" data-url="https://app.revu.cloud"><script src="https://widget.reviewability.com/js/widgetAdv.min.js" async></script></div><script class="json-ld-content" type="application/ld+json"></script>
        </div>
        */}
    </div>
  );
};

export default ContactContent;
