import React from 'react';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import HomePage from './views/Homepage';
import ServicesPage from './views/ServicesPage'; // Import your ServicesPage component
import ContactPage from './views/ContactPage';
import Articles from './views/contents/articles/articles'; // Dynamic articles router
import NotFound from './views/NotFound';
import './App.css';
import './firebase';

import FullGrooming from './views/contents/services/full-grooming'; // Import individual service details
import BathBlowDry from './views/contents/services/bath-blow-dry'; // Add imports for other services
import PuppyIntroduction from './views/contents/services/puppy-introduction'; // Add imports for other services
import DeShedding from './views/contents/services/de-shedding';
import MaintenanceTrim from './views/contents/services/maintenance-trim';
import BathHygienTidy from './views/contents/services/bath-hygiene-tidy';
import HandStripping from './views/contents/services/hand-stripping';
import TeethCleaning from './views/contents/services/teeth-cleaning';

import './App.css';
import './firebase';

function App() {
  return (
    <Router>
      <div className="App">
        <Routes>
          {/* Define the route for the home page (About Us) */}
          <Route path="/" element={<HomePage />} />
          <Route path="/index" element={<HomePage />} />
          
          {/* Define the route for the services overview page */}
          <Route path="/services" element={<ServicesPage />} />
          
          {/* Define the route for the contact page */}
          <Route path="/contact" element={<ContactPage />} />
          <Route path="/connect" element={<ContactPage />} />
          
          {/* Define routes for individual service pages */}
          <Route path="/services/full-grooming" element={<FullGrooming />} />
          <Route path="/full-dog-grooming-service" element={<FullGrooming />} />

          <Route path="/services/bath-blow-dry" element={<BathBlowDry />} />
          <Route path="/bath-blow-dry-brush" element={<BathBlowDry />} />
          
          <Route path="/services/puppy-introduction" element={<PuppyIntroduction />} />
          <Route path="/puppy-introduction" element={<PuppyIntroduction />} />
          
          <Route path="/services/de-shedding" element={<DeShedding />} />
          <Route path="/bath-de-shedding-undercoat-removal" element={<DeShedding />} />
          
          <Route path="/services/maintenance-trim" element={<MaintenanceTrim />} />
          <Route path="/bath-brush-maintenance-trim" element={<MaintenanceTrim />} />

          <Route path="/services/bath-hygiene-tidy" element={<BathHygienTidy />} />
          <Route path="/bath-hygiene-tidy" element={<BathHygienTidy />} />
          
          <Route path="/services/hand-stripping" element={<HandStripping />} />
          <Route path="/hand-stripping" element={<HandStripping />} />

          {<Route path="/services/teeth-cleaning" element={<TeethCleaning />} />}

          {/* Article Routing */}
          <Route path="/articles/*" element={<Articles />} />

          {/* Catch-all route for undefined pages */}
          <Route path="*" element={<NotFound />} />
        </Routes>
      </div>
    </Router>
  );
}

export default App;
