import React from 'react';
import { Helmet } from 'react-helmet';
import './articles.css';
//import Header from '../../Header.js';
//import Footer from '../../Footer.js';

const ArticleName = () => {
  return (
    <div className='articlePage'>
      <Helmet>
        <title>PageTitle | KK Dog Groomer</title>
        <meta name="description" content="ArticleSummary" />
      </Helmet>
      {/*<Header />*/}
      <div className="article-container">
        <h1>ArticleTitle</h1>
        <div className="article-content">
          <div className='content-txt'>
            <p>Executive Summary</p>
          </div>
          <div className='content-txt'>
            Article Body
          </div>
        </div>
      </div>
      {/*<Footer />*/}
    </div>
  );
};

export default ArticleName;
